import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import useModal from "hooks/useModal";
import useAuth from "hooks/useAuth";
import useViewCheck from "hooks/useViewCheck";

import video2 from "assets/images/main_video2.webp";
import pageChangeGreenSVG from "assets/images/icon/page_change_green.svg";
import pageChangePurpleSVG from "assets/images/icon/page_change_purple.svg";

// 채널 서비스를 위한 임포트
import ChannelService from "services/ChannelService";
import styled from "styled-components";

import Category from "./components/Category";
import { homeBannerCategoryList, homeBannerList } from "services/home";

const HOME_LOCATION = "HOME";
const VIDEO_INFO = {
  title: "우리회사의 AI 아이디어를 현실로 바꿨어요.",
  contents: "사용자의 스피치 패턴 분석을 통해\nAI 맞춤 솔루션을 개발했어요.",
  image: video2,
  url: "https://www.youtube.com/embed/dbWBf6JRiuM?si=tDbvk5PhM9CHVRjs",
  path: "dbWBf6JRiuM",
};
const TALLYFORM_URL = "https://tally.so/r/3yY0b4";
const MemoizedSlider = React.memo(Slider);

const Home = () => {
  const navigate = useNavigate();
  const { openModal } = useModal();
  const { isLoggedin, userInfo } = useAuth();
  const { isMobile } = useViewCheck();

  const [dragging, setDragging] = useState(false);

  const [defaultCategoryList, setDefaultCategoryList] = useState([]);
  const [partnerCategoryList, setPartnerCategoryList] = useState([]);
  const [blogDetailCategoryList, setBlogDetailCategoryList] = useState([]);
  const [blogSimpleCategoryList, setBlogSimpleCategoryList] = useState([]);

  const settings_dots = {
    dots: true,
    infinite: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    draggable: true,
    beforeChange: () => setDragging(true),
    afterChange: () => setDragging(false),
    appendDots: (dots) => (
      <div>
        <ul>{dots}</ul>
      </div>
    ),
  };

  /** init */
  useEffect(() => {
    ChannelService.boot({
      pluginKey: "d4aa1f68-4bf6-4560-8070-4b3ec83a7d87", // fill your plugin key
    });

    // openPopup();
    setEachBannerList();

    return () => {
      if (isLoggedin && localStorage.getItem("is-first") === "true")
        localStorage.setItem("is-first-home", userInfo.SEQ);
    };
  }, []);

  /** 팝업 띄우기 */
  // const openPopup = () => {
  //   const today = new Date();
  //   const HOME_VISITED = localStorage.getItem("homeVisited");
  //   if (!HOME_VISITED || HOME_VISITED < today) {
  //     openModal({
  //       type: "eventPopupModal",
  //     });
  //   }
  // };

  /** 각각의 배너 리스트 설정하기 */
  const setEachBannerList = () => {
    homeBannerCategoryList(HOME_LOCATION, (res) => {
      if (res.code !== 0) return;

      Object.keys(res.response).forEach((type) => {
        res.response[type].forEach((category) => {
          homeBannerList(category.SEQ, (res) => {
            if (res.code !== 0) return;

            const categoryObj = {
              IDX: category.ORDER_INDEX,
              NAME: category.NAME,
              ITEMS: res.response.slice(0, 9),
            };
            switch (type) {
              case "DEFAULT":
                setDefaultCategoryList((prev) => [...prev, categoryObj]);
                break;
              case "PARTNER":
                setPartnerCategoryList((prev) => [...prev, categoryObj]);
                break;
              case "BLOG_DETAIL":
                setBlogDetailCategoryList((prev) => [...prev, categoryObj]);
                break;
              case "BLOG_SIMPLE":
                setBlogSimpleCategoryList((prev) => [...prev, categoryObj]);
                break;
            }
          });
        });
      });
    });
  };

  /** 디폴트 배너 클릭 이벤트 */
  const onClickBanner = useCallback(
    (path) => (e) => {
      if (!path) return;
      if (dragging) {
        e.stopPropagation();
        return;
      }
      if (path.includes("https")) {
        window.open(path, "_blank");
      } else {
        if (path === "/aiproject/request") {
          if (isLoggedin) {
            navigate(path);
          } else {
            openModal({ type: "loginModal" });
          }
        } else {
          navigate(path);
        }
      }
    },
    [dragging]
  );

  /** 간편문의 버튼 클릭 이벤트 */
  const onClickSimpleRequest = () => {
    if (isLoggedin) {
      navigate("/aiproject/request");
    } else {
      window.open(TALLYFORM_URL, "_blank");
    }
  };

  /** 카테고리 리스트 순서에 맞게 정렬 */
  const sortOrder = (list) => {
    return list.sort((a, b) => a.IDX - b.IDX);
  };

  return (
    <StyledHome>
      {/* 디폴트 배너 (첫번쨰 카테고리만 보여주기) */}
      <DefaultBannerSection>
        <MemoizedSlider {...settings_dots}>
          {defaultCategoryList[0]?.ITEMS.map((item, i) => (
            <div
              className="default-banner-list"
              key={`default_${i}`}
              onClick={onClickBanner(item.URL)}
            >
              <img fetchpriority="high" src={item.FILE} alt="default-banner" />
            </div>
          ))}
        </MemoizedSlider>
      </DefaultBannerSection>
      {/* 디폴트 배너 */}

      {/* 카테고리 타이틀 - 파트너 상세 */}
      {sortOrder(partnerCategoryList).map((category, i) => (
        <StyledSection key={`partner_${i}`}>
          <StyledArticle>
            <Category
              type="partner"
              items={category.ITEMS}
              title={category.NAME}
              slidesToShow={isMobile ? 1 : 3}
            />
          </StyledArticle>
          {/* 간편 의뢰 버튼 */}
          {i === 0 && (
            <StyledMainBtn className="btn-green" onClick={onClickSimpleRequest}>
              <div className="text-wrapper">
                <span className="text-front">
                  외주비 얼마가 적절할까?&nbsp;
                </span>
                {isMobile && <br />}
                <span className="text-back">
                  전문가에게 무료 견적 받아보세요!
                </span>
              </div>
              <img src={pageChangePurpleSVG} alt="icon-arrow" />
            </StyledMainBtn>
          )}
          {/* 간편 의뢰 버튼 */}
        </StyledSection>
      ))}
      {/* 카테고리 타이틀 - 파트너 상세 */}

      {/* 비디오 아이프레임 */}
      <StyledVideoSection $isMobile={isMobile}>
        <StyledArticle>
          <hgroup>
            <h3>{VIDEO_INFO.title}</h3>
            <h4>{VIDEO_INFO.contents}</h4>
          </hgroup>
        </StyledArticle>
        <div className="video-wrapper">
          <iframe
            src={`${VIDEO_INFO.url}&autoplay=1&mute=1&controls=0&loop=1&playlist=${VIDEO_INFO.path}`}
            title={VIDEO_INFO.title}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
            loading="lazy"
            allowFullScreen
          />
        </div>
      </StyledVideoSection>
      {/* 비디오 아이프레임 */}

      {/* 카테고리 컴포넌트 - 블로그 상세 */}
      {sortOrder(blogDetailCategoryList).map((category, i) => (
        <StyledSection key={`blog_detail_${i}`}>
          <StyledArticle>
            <Category
              type="plain_detail"
              items={category.ITEMS}
              title={category.NAME}
              slidesToShow={isMobile ? 1 : 3}
            />
          </StyledArticle>
        </StyledSection>
      ))}
      {/* 카테고리 컴포넌트 - 블로그 상세 */}

      {/* 간편 의뢰 버튼 */}
      <StyledMainBtn className="btn-purple" onClick={onClickSimpleRequest}>
        <div className="text-wrapper">
          <span className="text-front">간편 문의하고 무료 매칭받기</span>
        </div>
        <img src={pageChangeGreenSVG} alt="icon-arrow" />
      </StyledMainBtn>
      {/* 간편 의뢰 버튼 */}

      {/* 카테고리 컴포넌트 - 블로그 간단 */}
      {sortOrder(blogSimpleCategoryList).map((category, i) => (
        <StyledSection key={`blog_simple_${i}`}>
          <StyledArticle>
            <Category
              type="plain_simple"
              items={category.ITEMS}
              title={category.NAME}
              slidesToShow={isMobile ? 1 : 4}
            />
          </StyledArticle>
        </StyledSection>
      ))}
      {/* 카테고리 컴포넌트 - 블로그 간단 */}
    </StyledHome>
  );
};

export default Home;

const StyledHome = styled.div`
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 660px) {
    margin-bottom: 50px;
  }
`;

const DefaultBannerSection = styled.section`
  position: relative;
  width: 100%;
  height: 322px;
  overflow: hidden;

  & .default-banner-list {
    width: 1920px;
    height: 300px;
    overflow: hidden;
    outline: none;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
    }
  }

  & .slick-slide {
    display: flex;
    justify-content: center;
    background-color: #000;
    max-height: 300px;
  }

  & .slick-dots {
    width: 100%;
    margin-top: 14px;
    position: unset;
    display: flex;
    justify-content: center;

    & > ul {
      width: 1028px;
      height: 8px;
      display: flex;
      text-align: left;

      & > li {
        margin: 0 6px 0 0;
        width: 8px;
        height: 8px;

        & > button {
          padding: 0;
          width: 8px;
          height: 8px;

          &::before {
            content: "";
            width: 8px;
            height: 8px;
            border-radius: 4px;
            color: #d6d6d6;
            background-color: #d6d6d6;
            opacity: 1;
          }
        }

        &.slick-active > button::before {
          background-color: #00ff85;
        }
      }
    }
  }

  @media (max-width: 660px) {
    height: 122px;

    & .default-banner-list {
      height: 100px;
    }

    & .slick-slide {
      max-height: 100px;
    }

    & .slick-dots {
      margin-top: 10px;

      & > ul {
        width: 340px;
      }
    }
  }
`;

const StyledSection = styled.section`
  margin-top: 40px;
  width: 1028px;

  @media (max-width: 660px) {
    width: 340px;
  }
`;

const StyledVideoSection = styled.section`
  margin-top: 40px;
  padding: 40px 0;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #eeeeee;

  & > .video-wrapper {
    margin-top: 10px;
    width: 740px;
    height: 416px;
    border-radius: 20px;
    overflow: hidden;

    & > iframe {
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 660px) {
    & > .video-wrapper {
      width: 340px;
      height: 191px;
    }
  }
`;

const StyledArticle = styled.article`
  width: 100%;
  text-align: center;

  & > hgroup {
    & > h3 {
      font-size: 20px;
      font-weight: 800;
      line-height: 133%;
    }

    & > h4 {
      font-size: 16px;
      font-weight: 400;
      line-height: 133%;
      white-space: pre-line;
    }
  }
`;

const StyledMainBtn = styled.button`
  margin-top: 40px;
  width: 1028px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 20px;
  transition: 0.1s;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
  }

  &:active {
    transform: scale(0.95);
  }

  &.btn-green {
    background-color: #00ff85;

    & > .text-wrapper > span.text-back {
      color: #5712eb;
    }
  }

  &.btn-purple {
    background-color: #5712eb;

    & > .text-wrapper > span {
      color: #00ff85;

      &.text-front {
        margin-right: 14px;
      }
    }
  }

  & > .text-wrapper > span {
    font-family: "Pretendard";
    font-size: 20px;
    font-weight: 600;
    color: #333;
  }

  & > .text-wrapper > span.text-back {
    margin-right: 4px;
  }

  & > img {
    width: 28px;
    height: 28px;
  }

  @media (max-width: 660px) {
    width: 340px;

    &.btn-green * {
      text-align: left;
      font-size: 16px !important;
    }
  }
`;

import AWS from "aws-sdk";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_IAM_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_IAM_SECRET_ACCESS_KEY,
});

const myBucket = new AWS.S3({
  params: { Bucket: process.env.REACT_APP_S3_BUCKET },
  region: process.env.REACT_APP_IAM_REGION,
});

export const fileAttach = (e, path, callback) => {
  const file = e.target.files[0];
  if (!file) return;
  const randomStr = Math.random().toString(36).split(".")[1];
  const params = {
    ACL: "public-read",
    Body: file,
    Bucket: process.env.REACT_APP_S3_BUCKET,
    Key: process.env.REACT_APP_S3_BUCKET_PATH + path + randomStr + file.name,
  };

  myBucket
    .putObject(params)
    .on("httpUploadProgress", (evt) => {
      // console.log(evt.loaded, evt.total)
      const url = `https://${process.env.REACT_APP_S3_BUCKET}.s3.${process.env.REACT_APP_IAM_REGION}.amazonaws.com/${params.Key}`;
      setTimeout(() => {
        callback(url, file.name);
      }, 1000);
    })
    .send((err) => {
      if (err) {
      }
    });
};

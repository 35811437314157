/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useModal from "hooks/useModal";
import useAuth from "hooks/useAuth";
import styled from "styled-components";
import {
  authProfile,
  authBusinessInfo,
  nicknameChangeAvailable,
  userNickNameCheck,
  userNicknameChange,
  userProfileImgChange,
  userHeroesView,
  businessApply,
  kmcTrCert,
  authPhoneNumModify,
} from "services/user";
import { partnerApply } from "services/heros";
import { nickNameRegEx } from "utils/regex";
import {
  aiIndustryData,
  aiSkillData,
  frameworkData,
  interestedAiTaskData,
} from "utils/data";

import "react-datepicker/dist/react-datepicker.css";

import { getUseBBusinessRegistration } from "services/useb";
import { fileNameFormat } from "utils/utils";
import useViewCheck from "hooks/useViewCheck";
import { fileAttach } from "utils/storage";

import CompanyProfile from "./components/CompanyProfile";
import ProfileDetail from "./components/ProfileDetail";
import Badge from "pages/Mypage/components/Badge";
import CareerCard from "./components/CareerCard";
import EducationCard from "./components/EducationCard";
import ToggleRadio from "components/Ui/ToggleRadio";
import "styles/profile.scss";
import RoundedButton from "components/Button/RoundedButton";
import Button from "components/Button/Button";
import SmallButton from "components/Button/SmallButton";
import BadgeWrapper from "components/Ui/BadgeWrapper";
import { useDispatch, useSelector } from "react-redux";
import { setPartnerData } from "store/userSlice";
const Profile = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const registerType = searchParams.get("type");
  const { openModal, closeModal } = useModal();
  const { isMobile } = useViewCheck();
  const {
    userInfoNicknameChange,
    userInfoImgChange,
    onUserInfoChange,
    isLoggedin,
    userInfo: authUserInfo,
  } = useAuth();
  const dispatch = useDispatch();
  const pData = useSelector((state) => state.partner.partnerData);
  const nicknameRef = useRef();
  const [userInfo, setUserInfo] = useState({
    NAME: "",
    USERNAME: "",
    NICKNAME: "",
    PHONE: "",
    PROFILE_IMG: "",
    ROLE: "",
    SEQ: "",
  });
  const [isNicknameChangeAvailable, setNicknameChangeAvailable] =
    useState(false);
  const [nicknameChangeMod, setNicknameChangeMod] = useState(false);
  const [nicknameInput, setNicknameInput] = useState("");
  const [nicknameError, setNicknameError] = useState({
    error: false,
    msg: "",
  });
  const [isNicknameChangeDone, setNicknameChangeDone] = useState(false);
  const [businessFile, setBusinessFile] = useState({
    file: "",
    fileName: "",
  });
  const [businessNum, setBusinessNum] = useState("");
  const [businessNumError, setBusinessNumError] = useState(false);
  const [businessNumCheckDone, setBusinessNumCheckDone] = useState(false);
  const [businessInfoChange, setBusinessInfoChange] = useState(false);
  const [businessName, setBusinessName] = useState("");
  const [businessScale, setBusinessScale] = useState("");
  const [businessLocation, setBusinessLocation] = useState(null);
  const [businessLocationDetails, setBusinessLocationDetails] = useState(null);
  const [registerMod, setRegisterMod] = useState(false);
  const [detailRegisterMod, setDetailRegisterMod] = useState(false);
  const [userType, setUserType] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [userInfoChange, setUserInfoChange] = useState(false);
  const [denied, setDenied] = useState(false);
  const [modifyMod, setModifyMod] = useState(false);
  // State to keep track of the original state
  const [partnerData, setPartner] = useState({
    career: null,
    workMethod: null,
    introduceSimple: null,
    introduceDetails: null,
    file: null,
    fileName: null,
    bailiwick: {},
    skillRetention: {},
    interestedAiTask: {},
    framework: {},
    careerInfoList: [],
    educationInfoList: [],
    businessInfo: {
      identificationNo: null,
      name: "",
      file: null,
      fileName: null,
      scale: "",
      location: "",
      locationDetails: "",
    },
  });
  const [businessInfo, setBusinessInfo] = useState({
    identificationNo: null,
    name: null,
    file: null,
    fileName: null,
    scale: null,
    location: null,
    locationDetails: null,
  });
  const [bailiwick, setBailiwick] = useState([]);
  const [skillRetention, setSkillRetention] = useState([]);
  const [interestedAiTask, setInterestedAiTask] = useState([]);
  const [framework, setFramework] = useState([]);
  const [editState, setEditState] = useState(false); // 일반유저 => 파트너 등록 취소 시 변경 체크
  const [heroesnfoChangeState, setHeroesnfoChangeState] = useState(false); // 히어로즈 유저 => 히어로즈 정보 변경 체크
  //kmc ------------
  const [trCert, setTrCert] = useState("");
  const reqKMCISFormRef = useRef();
  const trUrl =
    process.env.REACT_APP_API_HOST + process.env.REACT_APP_KMC_REDIRECT_URI;

  //kmc done -------

  const [careerSeq, setCareerSeq] = useState(1);
  const [eduSeq, setEduSeq] = useState(1);
  const initialCareerData = {
    companyName: "",
    current: null,
    department: null,
    endAt: "",
    interestedAiTask: null,
    performance: "",
    projectName: "",
    role: null,
    seq: careerSeq,
    startAt: "",
    user: null,
  };
  const initialEduData = {
    current: null,
    degree: null,
    educationInfo: "",
    endAt: "",
    major: null,
    performance: null,
    schoolName: "",
    seq: eduSeq,
    startAt: "",
    user: null,
  };
  const [careerCard, setCareerCard] = useState(null);
  const [eduCard, setEduCard] = useState(null);
  const [keyInput, setKeyInput] = useState("");

  // 카드 추가
  const handleCreateCareer = () => {
    setCareerSeq((prevCounter) => prevCounter + 1);
    const newCardId = careerSeq;
    if (careerCard === null) {
      setCareerCard([]);
    }
    setCareerCard((prevCards) => [
      ...prevCards,
      { ...initialCareerData, seq: newCardId + 1 },
    ]);
  };
  const handleCreateEdu = () => {
    setEduSeq((prevCounter) => prevCounter + 1);
    const newCardId = eduSeq;
    if (eduCard === null) {
      setEduCard([]);
    }
    setEduCard((prevCards) => [
      ...prevCards,
      { ...initialEduData, seq: newCardId + 1 },
    ]);
  };
  // 카드 삭제
  const handleCareerDelete = (targetId) => {
    openModal({
      type: "messageModal",
      contents: {
        title:
          userType === "business"
            ? `프로젝트 레퍼런스 제거`
            : "경력・프로젝트 레퍼런스 제거",
        message: `제거된 내용은 다시 복구할 수 없어요. 정말 제거하시겠어요?`,
        confirmText: "제거하기",
        confirmCallback: () => {
          const filteringArr = careerCard.filter(
            (card) => card.seq !== targetId
          );
          setCareerCard(filteringArr);
          closeModal();
        },
        cancelText: "돌아가기",
        cancelCallback: () => {
          closeModal();
        },
      },
    });
  };
  const handleEduDelete = (targetId) => {
    openModal({
      type: "messageModal",
      contents: {
        title: "상세 경력 및 학력 제거",
        message: `제거된 내용은 다시 복구할 수 없어요. 정말 제거하시겠어요?`,
        confirmText: "제거하기",
        confirmCallback: () => {
          const filteringArr = eduCard.filter((card) => card.seq !== targetId);
          setEduCard(filteringArr);
          closeModal();
        },
        cancelText: "돌아가기",
        cancelCallback: () => {
          closeModal();
        },
      },
    });
  };
  // 카드 컨텐츠 입력
  const handleCareerData = (value) => {
    setCareerCard((prevCards) =>
      prevCards.map((card) =>
        card.seq === value.seq ? { ...card, ...value } : card
      )
    );
  };
  const handleEduData = (value) => {
    setEduCard((prevCards) =>
      prevCards.map((card) =>
        card.seq === value.seq ? { ...card, ...value } : card
      )
    );
  };
  // 커리어카드 생성
  const getUserInfo = () => {
    authProfile((res) => {
      setUserInfo(res.response);
      const role = res.response.ROLE.split("_");
      if (role.includes("HEROS")) {
        setRegisterMod(true);
      }
      if (!location.state?.register) {
        setUserType(role.includes("BUSINESS") ? "business" : "individual");
      }
    });
  };

  useEffect(() => {
    if (!registerMod) return;
    // 등록모드일 때 히어로즈 정보를 뿌려준다.
    userHeroesView((res) => {
      if (res.code !== 0) return;
      const {
        CAREER,
        WORK_METHOD,
        INTRODUCE_SIMPLE,
        INTRODUCE_DETAILS,
        FILE,
        FILE_NAME,
        BAILIWICK,
        FRAMEWORK,
        INTERESTED_AI_TASK,
        SKILL_RETENTION,
        // 신규 api 값
        BUSINESS_INFO,
        CAREER_LIST,
        EDUCATION_LIST,
      } = res.response;
      if (res.response.IS_ADDITIONAL_INFO) {
        setDetailRegisterMod(true);
        // 데이터가 들어왔을 땐 등록취소버튼이 보이지 않고, 수정버튼만보여야한다.
        setModifyMod(true);
      }
      setPartner({
        career: CAREER,
        workMethod: WORK_METHOD,
        introduceSimple: INTRODUCE_SIMPLE,
        introduceDetails: INTRODUCE_DETAILS,
        file: FILE,
        fileName: FILE_NAME,
        bailiwick: BAILIWICK,
        skillRetention: SKILL_RETENTION,
        interestedAiTask: INTERESTED_AI_TASK,
        framework: FRAMEWORK,
        careerInfoList: CAREER_LIST,
        educationInfoList: EDUCATION_LIST,
        businessInfo: {
          identificationNo: BUSINESS_INFO.IDENTIFICATION_NO,
          name: BUSINESS_INFO.NAME,
          file: BUSINESS_INFO.FILE,
          fileName: BUSINESS_INFO.FILE_NAME,
          scale: BUSINESS_INFO.SCLAE,
          location: BUSINESS_INFO.LOCATION,
          locationDetails: BUSINESS_INFO.LOCATION_DETAILS,
        },
      });
      setBusinessInfo({
        identificationNo: BUSINESS_INFO.IDENTIFICATION_NO,
        name: BUSINESS_INFO.NAME,
        file: BUSINESS_INFO.FILE,
        fileName: BUSINESS_INFO.FILE_NAME,
        scale: BUSINESS_INFO.SCALE,
        location: BUSINESS_INFO.LOCATION,
        locationDetails: BUSINESS_INFO.LOCATION_DETAILS,
      });
      setBusinessFile({
        file: BUSINESS_INFO?.FILE,
        fileName: BUSINESS_INFO?.FILE_NAME,
      });
      // 비지니스 정보가 있으면
      if (BUSINESS_INFO.IDENTIFICATION_NO) {
        setBusinessNum(BUSINESS_INFO?.IDENTIFICATION_NO);
        BUSINESS_INFO.NAME && setBusinessName(BUSINESS_INFO?.NAME);
        setBusinessNumCheckDone(true);
      }

      if (EDUCATION_LIST === undefined || EDUCATION_LIST === null) {
        setEduCard(null);
      } else {
        setEduCard(convertArrayObjects(EDUCATION_LIST));
      }
      if (CAREER_LIST === undefined || EDUCATION_LIST === null) {
        setCareerCard(null);
      } else {
        setCareerCard(convertArrayObjects(CAREER_LIST));
      }

      const tempInterestedAiTaseArr = [];
      for (let i = 0; i < Object.keys(INTERESTED_AI_TASK).length; i++) {
        if (INTERESTED_AI_TASK[Object.keys(INTERESTED_AI_TASK)[i]]) {
          tempInterestedAiTaseArr.push(
            Object.keys(convertKeysToLowerCase(INTERESTED_AI_TASK))[i]
          );
        }
      }
      setInterestedAiTask(tempInterestedAiTaseArr);

      if (!BAILIWICK || !SKILL_RETENTION || !FRAMEWORK) return;
      const tempBailiwickArr = [];
      for (let i = 0; i < Object.keys(BAILIWICK).length; i++) {
        if (BAILIWICK[Object.keys(BAILIWICK)[i]]) {
          tempBailiwickArr.push(
            Object.keys(convertKeysToLowerCase(BAILIWICK))[i]
          );
        }
      }
      setBailiwick(tempBailiwickArr);

      const tempAiSkillArr = [];
      for (let i = 0; i < Object.keys(SKILL_RETENTION).length; i++) {
        if (SKILL_RETENTION[Object.keys(SKILL_RETENTION)[i]]) {
          tempAiSkillArr.push(
            Object.keys(convertKeysToLowerCase(SKILL_RETENTION))[i]
          );
        }
      }
      setSkillRetention(tempAiSkillArr);

      const tempFrameworkArr = [];
      for (let i = 0; i < Object.keys(FRAMEWORK).length; i++) {
        if (FRAMEWORK[Object.keys(FRAMEWORK)[i]]) {
          tempFrameworkArr.push(
            Object.keys(convertKeysToLowerCase(FRAMEWORK))[i]
          );
        }
      }
      setFramework(tempFrameworkArr);
    });
  }, [registerMod]);
  // 프로필 들어올 때 프로필등록 모드인지 확인
  useEffect(() => {
    registerType === "register" ? setRegisterMod(true) : setRegisterMod(false);
  }, [registerType]);

  useEffect(() => {
    if (!location.state) return;
    setUserType(location.state.register);
  }, [location]);
  useEffect(() => {
    getUserInfo();
    nicknameChangeAvailable((res) => {
      setNicknameChangeAvailable(res.response);
    });
    return () => {
      if (isLoggedin && localStorage.getItem("is-first") === "true")
        localStorage.setItem("is-first-profile", authUserInfo.SEQ);
    };
  }, []);

  // 비지니스 유저일 때 사업자번호 확인
  useEffect(() => {
    setBusinessNumError(false);
    const regex = /^[0-9]+$/;
    if (businessNum.length > 0 && !regex.test(businessNum)) {
      setBusinessNumError(true);
      if (businessNum.length === 0) {
        setBusinessNum(null);
      }
      return;
    }
  }, [businessNum]);

  // 기술 관련 뱃지 업데이트
  useEffect(() => {
    if (userType === "business") {
      if (!businessNumCheckDone) {
        setUserInfoChange(false);
        return;
      }
      if (businessInfoChange || imageURL) {
        setUserInfoChange(true);
      } else {
        setUserInfoChange(false);
      }
    } else {
      if (!imageURL) return;
      if (imageURL) {
        setUserInfoChange(true);
      } else {
        setUserInfoChange(false);
      }
    }
  }, [businessInfoChange, imageURL, userType, businessNumCheckDone]);

  // 프로필의 값이 변경됐을 때 업데이트
  const memoizedDefaultValue = useMemo(() => {
    return setPartner((prevState) => ({
      ...prevState,
      careerInfoList: careerCard,
      educationInfoList: eduCard,
      businessInfo: {
        ...businessInfo,
        name: businessInfo.name,
        scale: businessInfo.scale,
        location: businessInfo.location,
        locationDetails: businessInfo.locationDetails,
      },
    }));
  }, [
    businessInfo,
    businessName,
    businessScale,
    businessLocation,
    businessLocationDetails,
    careerCard,
    eduCard,
  ]);
  const handleRegenerateArray = (
    interestedAiTask,
    bailiwick,
    skillRetention,
    framework
  ) => {
    let tempAiIndustry = {};
    let tempAiSkill = {};
    let tempframework = {};
    let tempInterestedAiTaseArr = {};

    interestedAiTaskData.forEach((item) => {
      tempInterestedAiTaseArr = {
        ...tempInterestedAiTaseArr,
        [item.id]: interestedAiTask.includes(item.id),
      };
    });

    aiIndustryData.forEach((item) => {
      tempAiIndustry = {
        ...tempAiIndustry,
        [item.id]: bailiwick.includes(item.id),
      };
    });

    aiSkillData.forEach((item) => {
      tempAiSkill = {
        ...tempAiSkill,
        [item.id]: skillRetention.includes(item.id),
      };
    });

    frameworkData.forEach((item) => {
      tempframework = {
        ...tempframework,
        [item.id]: framework.includes(item.id),
      };
    });

    return {
      tempAiIndustry,
      tempAiSkill,
      tempframework,
      tempInterestedAiTaseArr,
    };
  };

  const memoizedValue = useMemo(() => {
    const {
      tempAiIndustry,
      tempAiSkill,
      tempframework,
      tempInterestedAiTaseArr,
    } = handleRegenerateArray(
      interestedAiTask,
      bailiwick,
      skillRetention,
      framework
    );
    const newValue = {
      interestedAiTask: tempInterestedAiTaseArr,
      skillRetention: tempAiSkill,
      bailiwick: tempAiIndustry,
      framework: tempframework,
    };
    return newValue;
  }, [bailiwick, skillRetention, framework, interestedAiTask]);

  useEffect(() => {
    setPartner({ ...partnerData, ...memoizedValue });
  }, [memoizedValue, memoizedDefaultValue]);

  useEffect(() => {
    validateData(partnerData, interestedAiTask);
  }, [
    partnerData,
    interestedAiTask,
    heroesnfoChangeState,
    businessNumCheckDone,
  ]);
  useEffect(() => {
    if (interestedAiTask.length > 0) {
      interestedAiTask.filter((item) => item !== "seq").length === 0 &&
        setHeroesnfoChangeState(false);
    }
  }, [interestedAiTask]);
  const validateData = (value, skills) => {
    if (value === undefined) return;
    const {
      introduceSimple,
      introduceDetails,
      career,
      workMethod,
      businessInfo,
    } = value;
    if (
      introduceSimple === null ||
      introduceSimple === undefined ||
      introduceSimple.length === 0 ||
      introduceDetails === null ||
      introduceDetails === undefined ||
      introduceDetails.length === 0 ||
      career === null ||
      workMethod === null ||
      skills.filter((item) => item !== "seq").length === 0
    ) {
      setHeroesnfoChangeState(true);
    } else if (
      (userType === "business" && businessInfo.name === "") ||
      (userType === "business" && businessInfo.location === "") ||
      (userType === "business" && businessInfo.locationDetails === null) ||
      (businessNum && !businessNumCheckDone)
    ) {
      setHeroesnfoChangeState(true);
    } else {
      setHeroesnfoChangeState(false);
    }
  };
  // 파트너 데이터 업데이트
  const handlePartnerData = (value) => {
    if (value) {
      setPartner((prevData) => ({
        ...prevData,
        ...value,
      }));
    }
  };
  function convertKeysToLowerCase(obj) {
    const convertedObject = {};
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const snakeCaseKey = key
          .toLowerCase()
          .replace(/_([a-z])/g, (match, p1) => p1.toUpperCase()); // Convert snake_case to camelCase
        convertedObject[snakeCaseKey] = obj[key];
      }
    }
    return convertedObject;
  }
  function convertArrayObjects(arr) {
    return arr.map((obj) => convertKeysToLowerCase(obj));
  }
  const businessNumCheck = useCallback(() => {
    const body = {
      biz_no: businessNum,
    };
    getUseBBusinessRegistration(body, (res) => {
      if (res === "error") {
        openModal({
          type: "messageModal",
          contents: {
            title: "기업(사업자)인증 실패",
            message: `입력된 사업자번호를 다시 확인해 주세요.\n계속하여 문제가 발생하면 고객센터로 문의해 주세요.`,
            confirmText: "확인",
            confirmCallback: () => {
              closeModal();
            },
          },
        });
        return;
      }
      if (
        !(
          Number(res.tax_type_code) === 4 ||
          Number(res.tax_type_code) === 5 ||
          Number(res.tax_type_code) === 99
        )
      ) {
        setBusinessNumCheckDone(true);
        setBusinessInfoChange(true);
        openModal({
          type: "messageModal",
          contents: {
            title: "기업(사업자)인증 완료",
            message: `기업 인증이 완료됐어요.`,
            confirmText: "확인",
            confirmCallback: () => {
              closeModal();
            },
          },
        });
      } else {
        setBusinessNumCheckDone(false);
        openModal({
          type: "messageModal",
          contents: {
            title: "기업(사업자)인증 실패",
            message: `입력된 사업자번호를 다시 확인해 주세요.\n계속하여 문제가 발생하면 고객센터로 문의해 주세요.`,
            confirmText: "확인",
            confirmCallback: () => {
              closeModal();
            },
          },
        });
      }
    });
  }, [businessNum]);

  const onBusinessFileChange = (e) => {
    if (e.target.files[0].size > 5000000) {
      openModal({
        type: "messageModal",
        contents: {
          title: "요청에 실패했습니다",
          message: "5MB 이상의 파일은 첨부할 수 없어요.",
          confirmText: "확인",
          confirmCallback: () => {
            closeModal();
          },
        },
      });
      return;
    }
    fileAttach(e, "profileBusinessDoc/", (downloadURL, fileName) => {
      setBusinessFile({
        file: downloadURL,
        fileName: fileName,
      });
      setPartner({
        ...partnerData,
        businessInfo: {
          ...partnerData.businessInfo,
          file: downloadURL,
          fileName: fileName,
        },
      });
      setBusinessInfoChange(true);
    });
  };

  const duplicateCheck = useCallback(() => {
    if (nickNameRegEx(nicknameInput)) {
      userNickNameCheck(nicknameInput, (res) => {
        if (res.response) {
          setNicknameError({
            error: true,
            msg: `닉네임을 사용할 수 없어요.`,
          });
        } else {
          setHeroesnfoChangeState(true);
          setNicknameChangeDone(true);
          setUserInfoChange(true);
        }
      });
    } else {
      setNicknameError({
        error: true,
        msg: `한글,영문,숫자 조합 최대 20자리 닉네임이\n아니에요.`,
      });
    }
  }, [nicknameInput]);
  const onImageChange = (e) => {
    if (e.target.files[0].size > 5000000) {
      openModal({
        type: "messageModal",
        contents: {
          title: "요청에 실패했습니다",
          message: `5MB 이상의 파일은 첨부할 수 없어요.`,
          confirmText: "확인",
          confirmCallback: () => {
            closeModal();
          },
        },
      });
      return;
    }
    fileAttach(e, "profileImg/", (downloadURL, fileName) => {
      setImageURL(downloadURL);
    });
  };
  const saveProfile = () => {
    if (nicknameInput && isNicknameChangeDone) {
      userNicknameChange(nicknameInput, (res) => {
        if (res.code === 0) {
          if (imageURL) {
            userProfileImgChange(imageURL, (res) => {
              if (res.code === 0) {
                openModal({
                  type: "messageModal",
                  contents: {
                    title: "프로필 등록/수정 완료",
                    message: `정상적으로 프로필이 등록/수정되었어요.`,
                    confirmText: "확인",
                    confirmCallback: () => {
                      closeModal();
                      setDenied(false);
                      setUserInfoChange(false);
                      setImageURL("");
                      userInfoImgChange(imageURL);
                      userInfoNicknameChange(nicknameInput);
                      setNicknameChangeDone(true);
                      navigate("/");
                    },
                  },
                });
              }
            });
          } else {
            openModal({
              type: "messageModal",
              contents: {
                title: "프로필 등록/수정 완료",
                message: `정상적으로 프로필이 등록/수정되었어요.`,
                confirmText: "확인",
                confirmCallback: () => {
                  closeModal();
                  setDenied(false);
                  setUserInfoChange(false);
                  setImageURL("");
                  userInfoNicknameChange(nicknameInput);
                  setNicknameChangeDone(true);
                  navigate("/");
                },
              },
            });
          }
        }
      });
    } else {
      if (imageURL) {
        userProfileImgChange(imageURL, (res) => {
          if (res.code === 0) {
            userInfoImgChange(imageURL);
            openModal({
              type: "messageModal",
              contents: {
                title: "프로필 등록/수정 완료",
                message: `정상적으로 프로필이 등록/수정되었어요.`,
                confirmText: "확인",
                confirmCallback: () => {
                  closeModal();
                  setDenied(false);
                  setUserInfoChange(false);
                  setImageURL("");
                  navigate("/");
                },
              },
            });
          }
        });
      }
    }
  };

  //kmc++++++++++++++++++++++
  useEffect(() => {
    window.addEventListener("message", kmcResult, false);
    return () => {
      window.removeEventListener("message", kmcResult);
    };
  }, []);

  const kmcResult = (e) => {
    if (e.origin !== process.env.REACT_APP_API_HOST) return;
    const { DI, mNumber, name } = e.data;
    phoneNumChangeHandler(mNumber, DI, name);
    window.newPopup.close();
    setTimeout(() => {
      delete window.newPopup;
    }, 100);
  };

  const phoneNumChangeHandler = (phone, di, name) => {
    const body = {
      phone,
      di,
      name,
    };
    authPhoneNumModify(body, (res) => {
      if (res.code !== 0) {
        openModal({
          type: "messageModal",
          contents: {
            title: "변경이 불가능해요.",
            message:
              "타인의 계정 정보로는 변경이 불가능해요.\n개명을 했거나, 연락처가 변경된 경우에만 업데이트가 가능해요.",
            confirmText: "확인",
            confirmCallback: () => {
              setDenied(true);

              closeModal();
            },
          },
        });
        return;
      } else if (phone === userInfo.PHONE && name === userInfo.NAME) {
        openModal({
          type: "messageModal",
          contents: {
            title: "동일한 계정 정보에요.",
            message: "현재 이용 중이신 연락처와 동일해요.",
            confirmText: "확인",
            confirmCallback: () => {
              setDenied(true);

              closeModal();
            },
          },
        });
        return;
      } else {
        openModal({
          type: "messageModal",
          contents: {
            title: "계정 정보가 변경되었어요.",
            message: "정보 변경이 완료되었어요.",
            confirmText: "확인",
            confirmCallback: () => {
              setDenied(false);

              getUserInfo();
              closeModal();
            },
          },
        });
      }
    });
  };

  useEffect(() => {
    if (!trCert) return;
    openKMCISWindow();
  }, [trCert]);
  const smsAuth = () => {
    let randomStr = Math.random().toString(36).split(".")[1];
    kmcTrCert(randomStr, "PROFILE", (res) => {
      setTrCert(res.response);
    });
  };
  const openKMCISWindow = () => {
    window.newPopup = window.open(
      "",
      "KMCISWindow",
      "width=425, height=550, resizable=0, scrollbars=no, status=0, titlebar=0, toolbar=0"
    );

    reqKMCISFormRef.current.target = "KMCISWindow";

    reqKMCISFormRef.current.action =
      "https://www.kmcert.com/kmcis/web/kmcisReq.jsp";
    reqKMCISFormRef.current.submit();
  };

  // 아래부터 파트너 등록 함수 ++++++++++++++++++++++++++++++++++++++
  const portfolioAttach = (e) => {
    if (e.target.files[0].size > 5000000) {
      openModal({
        type: "messageModal",
        contents: {
          title: "요청에 실패했습니다",
          message: "5MB 이상의 파일은 첨부할 수 없어요.",
          confirmText: "확인",
          confirmCallback: () => {
            closeModal();
          },
        },
      });
      return;
    }
    fileAttach(e, "profilePortfolio/", (downloadURL, fileName) => {
      setPartner({
        ...partnerData,
        file: downloadURL,
        fileName: fileName,
      });
    });
  };

  const registerModInit = () => {
    setEditState(false);
    setBailiwick([]);
    setSkillRetention([]);
    setInterestedAiTask([]);
    setFramework([]);
  };
  // 필수값 체크 및 파라미터 정의0
  const paramParser = (value) => {
    if (value === "individual") {
      value = "personal";
    }
    return value.toUpperCase();
  };
  // 뱃지 활성 비활성 확인 함수
  const checkForFalseValues = (obj) => {
    const hasTrueValue = Object.values(obj).some((value) => value === true);
    if (!hasTrueValue) {
      // openModal({
      //   type: "messageModal",
      //   contents: {
      //     title: "최소 입력 확인",
      //     message: `최소 1개 이상의 스택을 선택해주세요.`,
      //     confirmText: "확인",
      //     confirmCallback: () => {
      //       setDenied(true);
      //       closeModal();
      //     },
      //   },
      // });
      return false;
    } else {
      setDenied(false);
      return true;
    }
  };
  const handleKeyInput = (key) => {
    setKeyInput(key);
    switch (key) {
      case "name":
        return "회사명";
      case "introduceSimple":
        return "파트너 한줄소개";
      case "introduceDetails":
        return "파트너 상세소개";
      case "career":
        return "파트너 업력";
      case "location":
      case "locationDetails":
        return "지역선택";
      case "workMethod":
        return "근무형태";
      case "scale":
        return "기업규모";
      default:
        break;
    }
  };
  // 필수값 입력 여부 확인 함수
  const checkForEmptyValues = (obj) => {
    const excludedKeys = [
      "introduceSimple",
      "introduceDetails",
      "career",
      "workMethod",
      "scale",
    ];
    Object.entries(obj).forEach(([key, value]) => {
      if (
        (value == null || value === "" || value === undefined) &&
        excludedKeys.includes(key)
      ) {
        openModal({
          type: "messageModal",
          contents: {
            title: "필수값 입력 확인",
            message: `필수 입력값을 입력해주세요.\n${handleKeyInput(key)}`,
            confirmText: "확인",
            confirmCallback: () => {
              setDenied(true);
              handleKeyInput(key);
              closeModal();
            },
          },
        });
        return false;
      } else if (key === "interestedAiTask") {
        checkForFalseValues(obj.interestedAiTask);
      } else {
        setDenied(false);
        return true;
      }
    });
  };
  const checkBusinessValues = (value) => {
    const excludedKeys = [
      "name",
      "workMethod",
      "location",
      "locationDetails",
      "scale",
    ];

    for (const [key, val] of Object.entries(value)) {
      if (
        (val === null || val === "" || val === undefined) &&
        excludedKeys.includes(key)
      ) {
        setHeroesnfoChangeState(false);
        openModal({
          type: "messageModal",
          contents: {
            title: "필수값 입력 확인",
            message: `필수 입력값을 입력해주세요.\n${handleKeyInput(key)}`,
            confirmText: "확인",
            confirmCallback: () => {
              setDenied(true);
              handleKeyInput(key);
              closeModal();
            },
          },
        });
        return false;
      } else {
        setHeroesnfoChangeState(true);
        setDenied(false);
      }
    }
    setHeroesnfoChangeState(true);
    return true;
  };
  function objectToArray(obj) {
    return Object.entries(obj).map(([key, value]) => ({ name: key, value }));
  }
  const checkCareerEduList = (value) => {
    //파트너데이터의 직업리스트를 찾는다.
    const careerList = value.careerInfoList;
    const eduList = value.educationInfoList;
    const filteredCareer = careerList.filter(
      (item) =>
        item.companyName.length > 0 &&
        item.performance.length > 0 &&
        item.projectName.length > 0 &&
        objectToArray(item.interestedAiTask).filter(
          (task) => task.value === true
        ).length > 0
    );
    const filteredEdu = eduList.filter(
      (item) => item.educationInfo.length > 0 && item.schoolName.length > 0
    );
    return {
      ...partnerData,
      careerInfoList: filteredCareer.length === 0 ? null : filteredCareer,
      educationInfoList: filteredEdu.length === 0 ? null : filteredEdu,
    };
  };
  const checkPartnerData = (value) => {
    checkForEmptyValues(value);
    if (userType === "business") {
      if (
        checkBusinessValues(value.businessInfo) &&
        checkForFalseValues(value.interestedAiTask)
      ) {
        return true;
      }
    } else {
      if (checkForFalseValues(value.interestedAiTask)) {
        return true;
      }
    }
    setHeroesnfoChangeState(false);
    return false;
  };

  const applyPartnerSave = (type) => {
    // 데이터파일 값 확인
    checkPartnerData(partnerData);
    const reqParam = paramParser(userType);
    const businessBody = partnerData.businessInfo;
    // 개인도 사업자번호를 받게 되어 변경함
    businessBody.identificationNo =
      businessNum.length === 0 ? null : businessNum;
    if (businessNum.length > 0 && businessNum.length < 10) {
      //setBusinessNumCheckDone(false);
      setHeroesnfoChangeState(false);
      openModal({
        type: "messageModal",
        contents: {
          title: "등록 오류",
          message: `사업자 번호를 확인해 주세요`,
          confirmText: "확인",
          confirmCallback: () => {
            setDenied(true);
            closeModal();
          },
        },
      });
      return;
    }
    // businessApply(businessBody, (res) => {
    //   setHeroesnfoChangeState(false);
    //   if (res.code !== 0) {
    //     openModal({
    //       type: "messageModal",
    //       contents: {
    //         title: "등록 오류",
    //         message: `사업자 번호를 확인해 주세요`,
    //         confirmText: "확인",
    //         confirmCallback: () => {
    //           setDenied(true);
    //           closeModal();
    //         },
    //       },
    //     });
    //     return;
    //   } else {
    //     setDenied(false);
    //   }
    // });
    //checkCareerEduList(partnerData);
    checkPartnerData(partnerData) &&
      applyPartnerHandler(reqParam, type, partnerData);
  };

  const applyPartnerHandler = (param, type, body) => {
    saveProfile();
    partnerApply(param, type, body, (res) => {
      if (res.code === 0) {
        if (userInfoChange) {
          openModal({
            type: "messageModal",
            contents: {
              title: "프로필 등록/수정 완료",
              message: `정상적으로 프로필이 등록/수정되었어요.`,
              confirmText: "확인",
              confirmCallback: () => {
                setDenied(false);
                closeModal();
                onUserInfoChange();
                navigate("/");
              },
            },
          });
        } else if (res.code === 40) {
          setHeroesnfoChangeState(false);
          openModal({
            type: "messageModal",
            contents: {
              title: "요청에 실패했습니다",
              message:
                "예기치 못한 오류가 발생했습니다. 나중에 다시 시도해 주세요",
              confirmText: "확인",
              confirmCallback: () => {
                setDenied(true);
                closeModal();
              },
            },
          });
          return;
        } else {
          openModal({
            type: "messageModal",
            contents: {
              title: "프로필 등록/수정 완료",
              message: `정상적으로 프로필이 등록/수정되었어요.`,
              confirmText: "확인",
              confirmCallback: () => {
                setDenied(false);
                closeModal();
                onUserInfoChange();
                navigate("/");
              },
            },
          });
        }
      }
    });
  };

  const handleBusinessNum = (value) => {
    setBusinessNum(value);
    setBusinessInfo({ ...businessInfo, identificationNo: value });
  };
  const handleNickNameCheck = (value) => {
    //닉네임 입력시 정규표현식 검사
    if (nickNameRegEx(value)) {
      setNicknameError({
        error: false,
        msg: "",
      });
    } else {
      setNicknameError({
        error: true,
        msg: `한글,영문,숫자 조합 최대 20자리 닉네임이\n아니에요.`,
      });
      setNicknameChangeDone(false);
    }
    setNicknameInput(value);
  };
  const handleReset = () => {
    setPartner({
      ...partnerData,
      career: null,
      workMethod: null,
      introduceSimple: null,
      introduceDetails: null,
      file: null,
      fileName: null,
      bailiwick: {},
      skillRetention: {},
      interestedAiTask: {},
      framework: {},
    });
    setBailiwick([]);
    setSkillRetention([]);
    setInterestedAiTask([]);
    setFramework([]);
    setEduCard(null);
    setCareerCard(null);
    setBusinessFile({ file: null, fileName: null });
    setBusinessName(null);
    setBusinessScale(null);
    setBusinessLocation(null);
    setBusinessLocationDetails(null);
    setBusinessNumCheckDone(false);
    setBusinessInfo({
      ...partnerData.businessInfo,
      identificationNo: null,
      name: null,
      file: null,
      fileName: null,
      scale: null,
      location: null,
      locationDetails: null,
    });
    setBusinessNum("");
  };
  return (
    <div className="mypage-con">
      <div className="section-tit" style={{ marginBottom: "10px" }}>
        기본 프로필
      </div>
      <ToggleRadio className="col-2">
        {/* 기업 */}
        <label className="input-label required">
          회원 유형
          {/* <TitleTooltip textArr={clientTypeText} isMobile={isMobile} /> */}
        </label>
        <div className="radio">
          <div className={userType === "business" ? "button bus" : "button"}>
            {/* 기업 / 개인 라디오 버튼 */}
            <label className="">
              <input
                type="radio"
                name="radio1"
                value="business"
                style={{ position: "absolute" }}
                checked={userType === "business"}
                onChange={(e) => {
                  openModal({
                    type: "messageModal",
                    contents: {
                      title: "회원 유형 변경",
                      message: `기존에 작성되었던 내용은 저장되지 않고 삭제돼요.\n그래도 변경하시겠어요?`,
                      confirmText: "변경하기",
                      confirmCallback: () => {
                        setUserType(e.target.value);
                        setTimeout(() => {
                          handleReset();
                        }, 100);
                        closeModal();
                      },
                      cancelText: "돌아가기",
                      cancelCallback: () => {
                        closeModal();
                      },
                    },
                  });
                }}
              />
              기업 회원
            </label>
            {/* 기업 / 개인 라디오 버튼 */}
          </div>
          <div className={userType === "individual" ? "button indi" : "button"}>
            <label>
              <input
                type="radio"
                name="radio1"
                value="individual"
                style={{ position: "absolute" }}
                checked={userType === "individual"}
                onChange={(e) => {
                  openModal({
                    type: "messageModal",
                    contents: {
                      title: "회원 유형 변경",
                      message: `기존에 작성되었던 내용은 저장되지 않고 삭제돼요.\n그래도 변경하시겠어요?`,
                      confirmText: "변경하기",
                      confirmCallback: () => {
                        setUserType(e.target.value);
                        setTimeout(() => {
                          handleReset();
                        }, 100);
                        closeModal();
                      },
                      cancelText: "돌아가기",
                      cancelCallback: () => {
                        closeModal();
                      },
                    },
                  });
                }}
              />
              개인 회원
            </label>
          </div>
        </div>
        {/* 개인 */}
      </ToggleRadio>
      {/* 프로필 시작 */}

      <div className="col-2">
        <div className="mypage-profile">
          <div className="left">
            <div className="input-wrap">
              <StyledLabel className="required">프로필 이미지</StyledLabel>
              <label className="input-file">
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => onImageChange(e)}
                />
                <div
                  className={`input-photo ${
                    imageURL || userInfo.PROFILE_IMG ? "set" : ""
                  }`}
                >
                  {(imageURL || userInfo.PROFILE_IMG) && (
                    <img
                      src={imageURL || userInfo.PROFILE_IMG}
                      alt=""
                      style={{
                        width: "100%",
                        objectFit: "cover",
                        height: "100%",
                      }}
                    />
                  )}
                </div>
              </label>
            </div>
          </div>
          <div className="right">
            <div className="input-wrap">
              <StyledLabel className="m-none required">이름</StyledLabel>
              <label className="input-label m-block">&nbsp;</label>
              <input
                type="text"
                className="input-text"
                value={userInfo.NAME}
                readOnly
              />
            </div>
            {!isMobile ? (
              <div className="input-wrap">
                <StyledLabel className="required">연락처</StyledLabel>
                <div className="btn-exist">
                  <input
                    type="text"
                    className="input-text"
                    value={userInfo.PHONE}
                    readOnly
                  />
                  <StyledButton
                    type="button"
                    className="btn-black type2"
                    onClick={() => {
                      smsAuth();
                    }}
                  >
                    재인증
                  </StyledButton>
                  <form
                    name="reqKMCISForm"
                    method="post"
                    action="#"
                    ref={reqKMCISFormRef}
                    style={{ display: "none" }}
                  >
                    <input type="hidden" name="tr_cert" value={trCert} />
                    <input type="hidden" name="tr_url" value={trUrl} />
                    <input type="submit" value="KMC" />
                  </form>
                </div>
              </div>
            ) : (
              <div className="input-wrap">
                <StyledLabel className="m-none required">이메일</StyledLabel>
                <label className="input-label m-block mt-15"></label>
                <input
                  type="text"
                  className="input-text"
                  value={userInfo.USERNAME}
                  readOnly
                />
              </div>
            )}
          </div>
        </div>

        <div className="ml-20">
          {!isMobile ? (
            <div className="input-wrap">
              <StyledLabel className="required">이메일</StyledLabel>
              <input
                type="text"
                className="input-text"
                value={userInfo.USERNAME}
                readOnly
              />
            </div>
          ) : (
            <div className="input-wrap">
              <StyledLabel className="required">연락처</StyledLabel>
              <div className="btn-exist">
                <input
                  type="text"
                  className="input-text"
                  value={userInfo.PHONE}
                  readOnly
                />
                <button
                  type="button"
                  className="btn btn-black type2"
                  onClick={() => {
                    smsAuth();
                  }}
                >
                  재인증
                </button>
                <form
                  name="reqKMCISForm"
                  method="post"
                  action="#"
                  ref={reqKMCISFormRef}
                  style={{ display: "none" }}
                >
                  <input type="hidden" name="tr_cert" value={trCert} />
                  <input type="hidden" name="tr_url" value={trUrl} />
                  <input type="submit" value="KMC" />
                </form>
              </div>
            </div>
          )}
          <div className="input-wrap">
            <StyledLabel className="required">닉네임</StyledLabel>
            <div className="btn-exist">
              <input
                type="text"
                className={`input-text ${nicknameError.error ? "error" : ""}`}
                value={nicknameChangeMod ? nicknameInput : userInfo.NICKNAME}
                onChange={(e) => handleNickNameCheck(e.target.value)}
                readOnly={!nicknameChangeMod}
                ref={nicknameRef}
                onBlur={() => {
                  if (!nicknameChangeMod) return;
                  if (nickNameRegEx(nicknameInput)) {
                    setNicknameError({
                      error: false,
                      msg: "",
                    });
                  } else {
                    setNicknameError({
                      error: true,
                      msg: `한글,영문,숫자 조합 최대 20자리 닉네임이\n아니에요.`,
                    });
                  }
                }}
              />
              {isNicknameChangeDone ? (
                <span className="check right"></span>
              ) : (
                <>
                  {nicknameChangeMod ? (
                    <button
                      onClick={() => {
                        duplicateCheck();
                      }}
                      type="button"
                      className="btn btn-black type2"
                      disabled={nicknameError.error}
                    >
                      중복 확인
                    </button>
                  ) : (
                    <StyledButton
                      onClick={() => {
                        openModal({
                          type: "messageModal",
                          contents: {
                            title: "참고해 주세요!",
                            message: `닉네임의 추가 변경은 100일 이후 가능해요.`,
                            confirmText: "확인",
                            confirmCallback: () => {
                              closeModal();
                            },
                          },
                        });
                        setNicknameChangeMod(true);
                        setNicknameInput(userInfo.NICKNAME);
                        nicknameRef.current.focus();
                      }}
                      type="button"
                      className="btn-black type2"
                      disabled={!isNicknameChangeAvailable}
                      style={{
                        display: !isNicknameChangeAvailable ? "none" : "",
                      }}
                    >
                      닉네임 변경
                    </StyledButton>
                  )}
                </>
              )}
            </div>
            {nicknameError.error && (
              <p className="input-guide error">{nicknameError.msg}</p>
            )}
            {!isNicknameChangeAvailable && (
              <p className="input-guide">닉네임 변경은 100일 이후 가능해요.</p>
            )}
          </div>
        </div>
      </div>
      {!registerMod && (
        <div className="btn-wrap mt-40">
          <button
            type="button"
            className="btn btn-lg btn-black type2 ml-20"
            style={isMobile ? { width: "100%" } : {}}
            disabled={!userInfoChange || !heroesnfoChangeState}
            onClick={() => {
              saveProfile(); //applyHeroesSave()
            }}
          >
            프로필{userInfo ? " 수정하기" : " 등록"}
          </button>
        </div>
      )}
      {console.log(partnerData)}
      {registerMod && (
        <ProfileDetail
          userType={userType}
          partnerData={partnerData}
          handlePartnerData={handlePartnerData}
          businessInfo={{
            ...partnerData.businessInfo,
            name: partnerData.businessInfo.name,
            scale: partnerData.businessInfo.scale,
            location: partnerData.businessInfo.location,
            locationDetails: partnerData.businessInfo.locationDetails,
          }}
          setBusinessInfo={setBusinessInfo}
          setBusinessName={setBusinessName}
          setBusinessScale={setBusinessScale}
          setBusinessLocation={setBusinessLocation}
          setBusinessLocationDetails={setBusinessLocationDetails}
          setInterestedAiTask={setInterestedAiTask}
          interestedAiTask={interestedAiTask}
          isMobile={isMobile}
          keyInput={keyInput}
        />
      )}
      {registerMod && !detailRegisterMod && (
        <div className="mt-20">
          <RoundedButton
            onClick={() => {
              setDetailRegisterMod((prev) => !prev);
              setModifyMod((prev) => !prev);
            }}
            isIcon={true}
            text={"추가 정보 등록하고 매칭율 높이기"}
            isMobile={isMobile}
          />
          <div
            className="tooltip guide top"
            style={{ left: "90px", top: "-55px" }}
          >
            {localStorage.getItem("is-first") === "true" &&
              localStorage.getItem("is-first-profile") !== authUserInfo.SEQ && (
                <div
                  className="tooltip-message"
                  style={{
                    borderRadius: "30px",
                    background: "#D9D9D9",
                    height: "30px",
                    padding: "10px 18px",
                    fontSize: "11px",
                  }}
                >
                  프로젝트 지원 시 필요한 정보예요!
                </div>
              )}
          </div>
        </div>
      )}
      {registerMod && detailRegisterMod && (
        <>
          <section className="flex" style={{ width: "100%" }}>
            <div>
              <section className="mt-20">
                <label className="input-label type2 m-mt-30">
                  {userType === "business"
                    ? "프로젝트 레퍼런스"
                    : "경력・프로젝트 레퍼런스"}
                  <span className="guide">
                    {userType === "business"
                      ? "수행 프로젝트 추가 시, 파트너 프로필에 레퍼런스 뱃지가 노출돼요."
                      : "최근 진행한 프로젝트부터 순차적으로 작성해 주세요."}
                  </span>
                </label>
                {careerCard &&
                  careerCard.map((card, idx) => (
                    <div key={`${card.seq}_${idx}`}>
                      <CareerCard
                        isFirst={idx === 0}
                        inMobile={isMobile}
                        seq={card.seq}
                        data={card}
                        handleCareerDelete={() => handleCareerDelete(card.seq)}
                        handleCareerData={handleCareerData}
                      />
                    </div>
                  ))}
                <SmallButton
                  style={{ marginTop: "10px", width: "120px" }}
                  isIcon={true}
                  icon={"add"}
                  onClick={handleCreateCareer}
                  text={"레퍼런스 추가"}
                />
              </section>
              {userType === "individual" && (
                <section className="mt-20">
                  <label className="input-label type2 m-mt-30">
                    학력
                    <span className="guide">최신 순으로 작성해 주세요.</span>
                  </label>
                  {eduCard &&
                    eduCard.map((card, idx) => (
                      <div key={`${card.seq}_${idx}`}>
                        <EducationCard
                          isFirst={idx === 0}
                          seq={card.seq}
                          data={card}
                          handleEduDelete={() => handleEduDelete(card.seq)}
                          handleEduData={handleEduData}
                        />
                      </div>
                    ))}
                  <SmallButton
                    style={{ marginTop: "10px" }}
                    isIcon={true}
                    icon={"add"}
                    onClick={handleCreateEdu}
                    text={"학력 추가"}
                  />
                </section>
              )}
              <section className="col-2 mt-20">
                <div className="input-wrap">
                  {userType !== "business" && (
                    <div>
                      <label className="input-label">포트폴리오</label>
                      <label className="input-file m-mt-16">
                        <input
                          type="file"
                          onChange={(e) => {
                            portfolioAttach(e);
                          }}
                        />
                        <span
                          className={`input-text ${
                            partnerData.file ? "correct" : ""
                          }`}
                        >
                          {partnerData.file
                            ? fileNameFormat(partnerData.fileName)
                            : isMobile
                            ? "포트폴리오 첨부 (jpg, png, pdf 최대 5MB 첨부)"
                            : "포트폴리오 첨부 (jpg, png, pdf 최대 5MB 첨부가능)"}
                        </span>
                      </label>
                    </div>
                  )}
                </div>
              </section>
              <section className="mt-20">
                <label className="input-label type2">
                  사업자 정보
                  <span className="guide">
                    {userType === "individual"
                      ? "사업자가 있는 개인일 경우, 사업자 인증을 진행해 주세요."
                      : "사업자 인증을 진행해 주세요."}
                  </span>
                </label>
                <CompanyProfile
                  businessNum={businessNum}
                  businessNumCheck={businessNumCheck}
                  numError={businessNumError}
                  numCheckDone={businessNumCheckDone}
                  businessFile={businessFile}
                  fileNameFormat={fileNameFormat}
                  handleBusinessNum={handleBusinessNum}
                  handleBusNumCheck={setBusinessNumCheckDone}
                  handleBusinessFile={onBusinessFileChange}
                  isMobile={isMobile}
                />
              </section>
            </div>

            <section className="flex mt-20" style={{ width: "100%" }}>
              <label className="input-label type2">
                매칭을 높여주는 키워드
              </label>
              <BadgeWrapper>
                <div>
                  <p>1. 기술을 적용했던 모든 유형을 선택해 주세요.</p>
                  {aiSkillData.map((item, idx) => (
                    <Badge
                      key={`${item.id}_${idx}`}
                      className={"input-checkbox"}
                      checkedArray={skillRetention}
                      setCheckArray={setSkillRetention}
                      badgeData={item}
                    />
                  ))}
                </div>
                <div>
                  <p>2. 기술을 적용했던 모든 산업 분야를 선택해 주세요.</p>
                  {aiIndustryData.map((item, idx) => (
                    <Badge
                      key={`${item.id}_${idx}`}
                      className={"input-checkbox"}
                      checkedArray={bailiwick}
                      setCheckArray={setBailiwick}
                      badgeData={item}
                    />
                  ))}
                </div>
                <div>
                  <p>3. 사용 가능한 프로그래밍 프레임워크를 선택해 주세요.</p>
                  {frameworkData.map((item) => (
                    <Badge
                      key={item.id}
                      badgeData={item}
                      className={"input-checkbox"}
                      setCheckArray={setFramework}
                      checkedArray={framework}
                    />
                  ))}
                </div>
              </BadgeWrapper>
            </section>
          </section>

          {/* 기존 히어로즈 프로필 상세 */}

          {!userInfo.ROLE?.split("_").includes("HEROS") && isMobile && (
            <button
              type="button"
              className="btn btn-underline txt-c--grey right"
              style={{ fontSize: "12px", width: "100%" }}
              onClick={() => {
                if (editState) {
                  openModal({
                    type: "messageModal",
                    contents: {
                      title: "파트너 등록 취소",
                      message: `파트너 등록 취소 시 입력하고 있던 내용들은 저장되지 않아요.\n정말 파트너 등록을 취소하시겠어요?`,
                      confirmText: "취소하기",
                      confirmCallback: () => {
                        setRegisterMod(false);
                        registerModInit();
                        navigate("/");

                        closeModal();
                      },
                      cancelText: "돌아가기",
                      cancelCallback: () => {
                        closeModal();
                      },
                    },
                  });
                } else {
                  setRegisterMod(false);
                }
              }}
            >
              파트너 등록 취소하기
            </button>
          )}
        </>
      )}
      {/* 등록모드가 아닐 때 파트너등록 버튼 */}
      <div className="btn-wrap mt-40">
        {registerMod && detailRegisterMod && (
          <>
            <Button
              text={"등록 취소"}
              color={"grey"}
              type={"half"}
              className={"mr-6"}
              onClick={() => {
                openModal({
                  type: "messageModal",
                  contents: {
                    title: "파트너 등록 취소",
                    message: `파트너 등록 취소 시 입력하고 있던 내용들은 저장되지 않아요.\n정말 파트너 등록을 취소하시겠어요?`,
                    confirmText: "취소하기",
                    confirmCallback: () => {
                      setRegisterMod(false);
                      registerModInit();
                      navigate("/");
                      closeModal();
                    },
                    cancelText: "돌아가기",
                    cancelCallback: () => {
                      closeModal();
                    },
                  },
                });
              }}
            />
            <Button
              text={"파트너 등록하기"}
              color={"black"}
              type={"full"}
              className={""}
              disabled={!heroesnfoChangeState}
              isMobile={isMobile}
              style={{ marginLeft: "6px" }}
              onClick={() => {
                !heroesnfoChangeState && applyPartnerSave(false); //applyHeroesSave()
              }}
            />
          </>
        )}
        {/* 일반 파트너 수정하기 */}
        {registerMod && !detailRegisterMod && (
          <>
            {!modifyMod && (
              <Button
                text={"등록 취소"}
                color={"grey"}
                type={"half"}
                className={"mr-6"}
                onClick={() => {
                  openModal({
                    type: "messageModal",
                    contents: {
                      title: "파트너 등록 취소",
                      message: `파트너 등록 취소 시 입력하고 있던 내용들은 저장되지 않아요.\n정말 파트너 등록을 취소하시겠어요?`,
                      confirmText: "취소하기",
                      confirmCallback: () => {
                        setRegisterMod(false);
                        registerModInit();
                        navigate("/");

                        closeModal();
                      },
                      cancelText: "돌아가기",
                      cancelCallback: () => {
                        closeModal();
                      },
                    },
                  });
                }}
              />
            )}
            <Button
              text={modifyMod ? "파트너 수정하기" : "파트너 등록하기"}
              color={"black"}
              type={"full"}
              className={""}
              disabled={!heroesnfoChangeState}
              isMobile={isMobile}
              style={{
                marginLeft: "6px",
                width: modifyMod ? "300px" : "200px",
              }}
              onClick={() => {
                applyPartnerSave(true); //applyHeroesSave()
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};

const StyledLabel = styled.label`
  display: block;
  width: fit-content;
  font-size: 1.6rem;
  font-weight: 600;
  margin: 20px 0 10px;
  position: relative;

  &.required {
    &:after {
      content: "*";
      color: #df2e47;
      position: absolute;
      right: -10px;
      top: 0;
    }
  }
`;

const StyledButton = styled.button`
  height: 34px;
  position: absolute;
  right: 0px;
  top: 8px;
  margin: 0px 18px 0px 6px;
  padding: 0 10px;
  border-radius: 5px;
  font-family: Pretendard;
  font-size: 14px;
  cursor: pointer;

  @media (max-width: $mo) {
    font-size: 1.4rem;
  }
`;

export default React.memo(Profile);

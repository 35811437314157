import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import useViewCheck from "hooks/useViewCheck";
import GuideFloatingBanner from "./GuideFloatingBanner";

const Layout = () => {
  const location = useLocation();
  const { isMobile } = useViewCheck();
  const [isHeaderOn, setHeaderOn] = useState(true);

  const [guideFloatingBannerOpen, setGuideFloatingBannerOpen] = useState(false);

  useEffect(() => {
    if (!isMobile) {
      setHeaderOn(true);
      setGuideFloatingBannerOpen(true);
      return;
    }

    if (location.pathname.split("/")[1] !== "join") {
      setHeaderOn(true);
    } else {
      setHeaderOn(false);
    }
  }, [location, isMobile]);

  useEffect(() => {
    // console.log("asdfaf");
    if (isMobile) {
      const today = new Date();
      const GUIDE_VISITED = localStorage.getItem("guideBannerVisited");
      if (!GUIDE_VISITED || GUIDE_VISITED < today) {
        // 배너 노출
        setGuideFloatingBannerOpen(true);
      }
    } else {
      setGuideFloatingBannerOpen(true);
    }
  }, []);

  return (
    <>
      {isHeaderOn && <Header />}
      <Outlet />
      <Footer />

      {/* PDF 가이드북 플로팅 팝업 */}
      <GuideFloatingBanner
        open={guideFloatingBannerOpen}
        close={() => setGuideFloatingBannerOpen(false)}
      />
    </>
  );
};

export default Layout;

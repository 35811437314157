import styled from "styled-components";
import GuideBannerImage from "assets/images/guide-floating-banner.png";
import ArrowNextSVG from "assets/images/icon/arrow_next_primary.svg";
import CloseSVG from "assets/images/icon/close_gray.svg";
import useViewCheck from "hooks/useViewCheck";
import { useEffect } from "react";
import { homePopupHits } from "services/home";

const GuideFloatingBanner = ({ open, close }) => {
  const { isMobile } = useViewCheck();

  useEffect(() => {
    if (isMobile) setMobileStyle();
  }, [open]);

  const setMobileStyle = () => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.removeProperty("overflow");
    }
  };

  const onClickBanner = () => {
    homePopupHits("GuideBanner", (res) => {
      if (res.code === 0) {
        return;
      }
    });
  };

  const closeBanner = () => {
    if (isMobile) {
      let expires = new Date();
      expires = expires.setHours(23, 59, 59, 0);
      localStorage.setItem("guideBannerVisited", expires);
    }
    close();
  };

  return (
    open && (
      <StyledWrapper className={isMobile && "mob"}>
        <div className="banner">
          <img
            className="close"
            src={CloseSVG}
            alt="close"
            onClick={closeBanner}
          />

          <div className="title">
            <span>PDF 가이드북</span>
            <span>무료 제공 이벤트</span>
          </div>

          <img className="banner-img" src={GuideBannerImage} alt="banner" />

          <a
            className="btn-wrapper"
            href="https://tally.so/r/3xpejo"
            target="_blank"
            onClick={onClickBanner}
          >
            이벤트 참여하고 가이드북 받기
            <img src={ArrowNextSVG} />
          </a>
        </div>
      </StyledWrapper>
    )
  );
};

export default GuideFloatingBanner;

const StyledWrapper = styled.div`
  &.mob {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 99999999;

    & > .banner {
      right: auto;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  & > .banner {
    position: fixed;
    bottom: 180px;
    right: 23px;
    width: 290px;
    height: 450px;
    display: flex;
    justify-content: center;
    border-radius: 16px;
    box-shadow: 0px 6px 4px 0px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    z-index: 10;

    & > .close {
      position: absolute;
      right: 20px;
      top: 20px;
      width: 17.7px;
      height: 17.7px;
      cursor: pointer;
    }

    & > .title {
      margin-top: 53px;
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;

      & > span {
        color: #000;
        text-align: center;
        font-family: Pretendard;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.8px;
      }
    }

    & > .banner-img {
      width: 100%;
    }

    & > .btn-wrapper {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 62px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: black;
      color: #00ff85;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
      letter-spacing: -1px;
      cursor: pointer;

      & > img {
        margin-left: 5.76px;
      }
    }
  }
`;
